import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes, PreloadAllModules } from '@angular/router';

const routes: Routes = [
 	{
		path: 'mht',
		loadChildren: () => import('./mht/mht.module').then( m => m.MhtModule)
	},
	{ path: '', redirectTo: '/mht/presentation', pathMatch: 'full' },
	{ path: '**', redirectTo: '/mht/presentation' },
];

const config: ExtraOptions = {
	useHash: false,
	onSameUrlNavigation: 'reload',
	preloadingStrategy: PreloadAllModules
};
	
@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})

export class AppRoutingModule {
}
