import {
	transition,
	trigger,
	query,
	style,
	animate,
	group,
	animateChild
	} from '@angular/animations';
import { h } from 'img-comparison-slider/dist/types/stencil-public-runtime';


	export const slideInAnimation =
	trigger('routeAnimations', [	  
	  	// transition('UserPage <=> PresentationPage', [
		// 	style({ position: 'relative' }),
		// 	query(':enter, :leave', [
		// 		style({
		// 			position: 'absolute',
		// 			width: '100vw',
		// 			height: '100vh'
		// 		})
		// 	]),
		// 	query(':enter', [
		// 		style({ top: '100%' })
		// 	]),
		// 	query(':leave', animateChild()),
		// 	group([
		// 		//   query(':leave', [
		// 		// 	animate('200ms ease-out', style({ top: '' }))
		// 		//   ]),
		// 		query(':enter', [
		// 			animate('300ms ease-out', style({ top: '0' }))
		// 		])
		// 	]),
		// 	query(':enter', animateChild()),
		// ]),
		transition('PresentationPage <=> DashboardPage', [
			style({ position: 'relative' }),
			query(':enter, :leave', [
				style({
					position: 'absolute',
					width: '100vw',
					height: '100vh'
				})
			]),
			query(':enter', [
				style({ right: '-100%' })
			]),
			query(':leave', animateChild()),
			group([
				//   query(':leave', [
				// 	animate('200ms ease-out', style({ top: '' }))
				//   ]),
				query(':enter', [
					animate('300ms ease-out', style({ right: '0' }))
				])
			]),
			query(':enter', animateChild()),
		]),
		transition('DashboardPage <=> ZonePage', [
			style({ position: 'relative' }),
			query(':enter, :leave', [
				style({
					position: 'absolute',
					width: '100vw',
					height: '100vh'
				})
			]),
			query(':enter', [
				style({ opacity: '0' })
			]),
			query(':leave', animateChild()),
			group([
				//   query(':leave', [
				// 	animate('200ms ease-out', style({ top: '' }))
				//   ]),
				query(':enter', [
					animate('600ms ease-out', style({  opacity: 1 }))
				])
			]),
			query(':enter', animateChild()),
		]),
		transition('ZonePage <=> SpotPage', [
			style({ position: 'relative' }),
			query(':enter, :leave', [
				style({
					position: 'absolute',
					width: '100vw',
					height: '100vh'
				})
			]),
			query(':enter', [
				style({ opacity: '0' })
			]),
			query(':leave', animateChild()),
			group([
				//   query(':leave', [
				// 	animate('200ms ease-out', style({ top: '' }))
				//   ]),
				query(':enter', [
					animate('600ms ease-out', style({  opacity: 1 }))
				])
			]),
			query(':enter', animateChild()),
		]),
		transition('DashboardPage <=> MapPage', [
			style({ position: 'relative' }),
			query(':enter, :leave', [
				style({
					position: 'absolute',
					width: '100vw',
					height: '100vh'
				})
			]),
			query(':enter', [
				style({ opacity: '0' })
			]),
			query(':leave', animateChild()),
			group([
				//   query(':leave', [
				// 	animate('200ms ease-out', style({ top: '' }))
				//   ]),
				query(':enter', [
					animate('600ms ease-out', style({  opacity: 1 }))
				])
			]),
			query(':enter', animateChild()),
		])
	]);