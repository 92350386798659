import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { VariablesService } from './variables.service';
import { UserService } from '../user/_services/user.service';

import { Router } from '@angular/router';


@Injectable()
export class HttppostInterceptor implements HttpInterceptor {

	public cloneReq: HttpRequest<any>;
	// public version: string = version;
	private requests: HttpRequest<any>[] = [];

		
	constructor(
		private variablesService: VariablesService,
		private userService: UserService,
		// private router:Router,
		private toastr: ToastrService,
	) {}

	removeRequest(req: HttpRequest<any>) {
		
		const i = this.requests.indexOf(req);
		if (i >= 0) {
		this.requests.splice(i, 1);
		}
		
 	}


    intercept(
		request: HttpRequest<any>, 
		next: HttpHandler
	): Observable<HttpEvent<any>> { 

		if(request.body && request.body.url && (request.body.url == 'authenticate' || request.body.url == 'google_oauth/callback' || request.body.url == 'facebook_oauth/callback')
		){

			this.cloneReq = request.clone(
				{
					url: this.variablesService.baseUrl + request.body.url,
					headers: request.headers
					.set('Content-Type', 'application/json')	
					// .set('X-Requested-With', 'XMLHttpRequest')			
					// .set('Accept', 'application/json')			
					// .set('X-CSRF-TOKEN', '000')
					// .set('Authorization', this.variablesService.webServiceAuthorization)
					// .set('x-api-key', this.variablesService.webServiceApikey)
					// .set('version', this.version)
					//.set('Authentication', this.authService.getToken())
				}
			);
			
		}else if(request.body && request.body.url ){
			
			this.cloneReq = request.clone(
				{
					url: this.variablesService.baseUrl + request.body.url, 
					headers: request.headers
					.set('Content-Type', 'application/json')
					.set('Authorization', `Bearer ${this.userService.getToken()}`)
					// .set('Authorization', `Bearer ${this.userService.getToken()}`)
					// .set('Authorization', this.variablesService.webServiceAuthorization)
					// .set('x-api-key', this.variablesService.webServiceApikey)
					
					// .set('version', this.version)
				}
			);

		}else{
			
			this.cloneReq = request.clone(
				{
					// url: this.variablesService.baseUrl + request.body.url, 
					headers: request.headers
					.set('Content-Type', 'application/json')
					.set('Authorization', `Bearer ${this.userService.getToken()}`)
					// .set('Authorization', this.variablesService.webServiceAuthorization)
					// .set('x-api-key', this.variablesService.webServiceApikey)
					
					// .set('version', this.version)
				}
			);

		}

	

		return next.handle(this.cloneReq).pipe(
            tap(evt => {
			
                if (evt instanceof HttpResponse) {
					this.removeRequest(this.cloneReq);
                }

            }),
            catchError((err: any) => {
				console.log(JSON.stringify(err));
                if(err instanceof HttpErrorResponse) {	
					
					if(err.error){
						if(err.error.errors){
							console.log(err.error.errors);
							for(let msg in err.error.errors){							
								this.toastr.error( err.error.errors[msg], 'Erreur');
							}																
						}
						if(err.error.message){						
							this.toastr.error( err.error.message, 'Erreur');
																							
						}
					}

					if(err.status == 401){
						this.userService.sendLogout(); 
						// this.router.navigate(['/pro/user/login'], {});
					}
						
					
                }
                return of(err);
			})
		);
		

    }
}

  