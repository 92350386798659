import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class VariablesService {

	public baseUrl: string = '';
	public isApp: boolean = true;
	public isPWA: boolean = true;

	constructor(

	) { 

		this.baseUrl = environment.apiUrl;
		this.isApp = environment.isApp;
		this.isPWA = environment.isPWA;
	}

}
