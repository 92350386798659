import { Component, DoCheck, Inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { AlertController, Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';
import { slideInAnimation } from './mht/_animations/animation';
import { UserService } from './mht/user/_services/user.service';
import { VariablesService } from './mht/_services/variables.service';

import { NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

// import { Plugins } from '@capacitor/core';
// const { App } = Plugins;
import { App } from '@capacitor/app';
import { ScreenOrientation } from '@capacitor/screen-orientation';
// const { App } = Plugins;
// const { SplashScreen } = Plugins;

// import { App } from '@capacitor/app'; 

import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	animations: [
		slideInAnimation
		// animation triggers go here
	  ]
})
export class AppComponent implements OnInit, DoCheck    {

	public splash: boolean = false;
	elem: any;
	isFullscreen:boolean = false;
	desktop:boolean = false;
	orientation: any;
	public isIOS: boolean = false;
	public pwa: boolean = false; 
	public goPWATimeout: boolean = false; 
	// public showPWATimeout: boolean = false; 
	public language: string;

	options: AnimationOptions = {
		// path: '/assets/lottie.json',
		path: '/assets/lottie-couleur.json',
	};

	styles: Partial<CSSStyleDeclaration> = {
		maxWidth: '500px',
		margin: '0 auto',
		// background: '#253334 url("/assets/bg_splash.png") no-repeat 50% 0'
		background: '#253334 url("/assets/bg_splash.png") no-repeat 50% 0'
	};

	constructor(
		private translate: TranslateService,
		private platform: Platform,
		// private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private userService: UserService, 
		private router: Router, 
		private zone: NgZone,
		public alertController: AlertController,
		private toastrService: ToastrService,
		private variablesService: VariablesService,
		@Inject(DOCUMENT) private document: any
	) {
		this.initializeApp();

			
			
	}

	animationCreated(animationItem: AnimationItem): void {
		// console.log(animationItem);
	}
	
	ngOnInit() {
		
		if(this.variablesService.isPWA) {
			this.pwa = true;
			this.elem = document.documentElement;
			// setTimeout(function(){
			// 	this.showPWATimeout = true;
			// 	console.log(this.showPWATimeout);
			// }, 5000);

			localStorage.removeItem('showPWATimeout')

			
		}else if(!this.variablesService.isApp) {
			this.elem = document.documentElement;
			this.desktop = true;
		}		
		if(this.platform.is('ios')){
			this.isIOS = true;
			// this.seeVideo3D();
		}
		// console.log(this.isIOS);
	}

	ngDoCheck () {
		
		if(this.variablesService.isPWA) {

			if(localStorage.getItem('showPWATimeout')){

				this.goPWATimeout = true;
			}
			
		}
	}

	fullscreenOn() {
		if (this.elem.requestFullscreen) {
			this.elem.requestFullscreen();
		} else if (this.elem.mozRequestFullScreen) {
			/* Firefox */
			this.elem.mozRequestFullScreen();
		} else if (this.elem.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			this.elem.webkitRequestFullscreen();
		} else if (this.elem.msRequestFullscreen) {
			/* IE/Edge */
			this.elem.msRequestFullscreen();
		}
		this.isFullscreen = true;
	}

	fullscreenOff() {
		if (this.document.exitFullscreen) {
			this.document.exitFullscreen();
		} else if (this.document.mozCancelFullScreen) {
			/* Firefox */
			this.document.mozCancelFullScreen();
		} else if (this.document.webkitExitFullscreen) {
			/* Chrome, Safari and Opera */
			this.document.webkitExitFullscreen();
		} else if (this.document.msExitFullscreen) {
			/* IE/Edge */
			this.document.msExitFullscreen();
		}
		this.isFullscreen = false;
	}

	async initializeApp() { 

		
		

		// other stuff...
		if(this.userService.getLanguage()){
			this.translate.setDefaultLang(this.userService.getLanguage())
		}else{
			this.translate.setDefaultLang('fr'); // add this
		}		

		this.language = this.translate.getDefaultLang();
			
		this.platform.ready().then(() => {

			// this.splashScreen.hide();
			
			setTimeout(() => {
				this.splash = true;
			}, 0);
			// }, 1500);
		
			setTimeout(() => {
				this.splash = false;
			}, 4700);
			// }, 6200);
			
			this.statusBar.styleDefault();
			
			App.addListener('backButton', () => {
				console.log('app capacitor 3 ');
				this.backButton();  
			});
	 	});
		
		App.addListener('appUrlOpen', (data: any) => {
			console.log('deeplink');		
			this.zone.run(() => {
				// Example url: https://beerswift.app/tabs/tab2
				// slug = /tabs/tab2
				let code: string;
				let isHash = 0;
				const slug = data.url.split("type").pop();
				if (slug && slug != data.url) {					
					console.log('slug');
					console.log(slug);
					code = slug;
				}
				
				const hash = data.url.split("hash").pop();
				if (hash && hash != data.url) {
					isHash = 1;
					console.log('hash');
					console.log(hash);
					code = hash.substring(1);
				}
				
				console.log('code'+code);
				console.log('hash'+hash);
				console.log('isHash'+isHash);
				console.log('slug'+slug);
				if (this.userService.checkToken() && code != '') {
			
					if(isHash == 0){
						this.userService.getQRCodeRouteFromType(code) 
						.subscribe(
						   (data: any) => {							
								console.log('getQRCodeRouteFromType');
								console.log(JSON.stringify(data));
								if(data.message){
									this.toastrService.success(data.message, 'Succès' );
								}
								if(data.entity_type == "spot"){
									if(data.entity_id == 9){
										data.entity_id = 8;
									}
									this.router.navigate(['/mht/spot/'+data.entity_id], {});
								}else if(data.entity_type == "area"){
									this.router.navigate(['/mht/zone/'+data.entity_id], {});
								}else if(data.entity_type == "circuit"){
									this.router.navigate(['/mht/map/'], {
										queryParams: {
											circuit_id: data.entity_id
										  },
									});
								}
									   
						   }
					   );
					}else{
						this.userService.getQRCodeRoute(code) 
						.subscribe(
						   (data: any) => {
							
								console.log('getQRCodeRoute');
								console.log(JSON.stringify(data));
								if(data.message){
									this.toastrService.success(data.message, 'Succès' );
								}
								if(data.entity_type == "spot"){
									if(data.entity_id == 9){
										data.entity_id = 8;
									}
									this.router.navigate(['/mht/spot/'+data.entity_id], {});
								}else if(data.entity_type == "area"){
									this.router.navigate(['/mht/zone/'+data.entity_id], {});
								}else if(data.entity_type == "circuit"){
									this.router.navigate(['/mht/map/'], {
										queryParams: {
											circuit_id: data.entity_id
										  },
									});
								}
									   
						   }
					   );
					}
				
					
				}else{
					
					this.userService.saveQRCodeRoute(isHash+code);

				}
				
				
				console.log('get_the_app');
				// If no match, do nothing - let regular routing
				// logic take over
			});
		});
	
		const orientation = await ScreenOrientation.orientation();
		this.orientation = orientation.type;
		console.log(this.orientation);

		ScreenOrientation.addListener('screenOrientationChange', (orientation: any) => {
			this.orientation = orientation.type;			
		});

	}

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
	}

	backButton(){

		this.alertController.create({
			header: 'Confirmation',
			subHeader: 'Voulez-vous vraiement quitter l\'application ?',
			// message: 'Enter your favorate place',
			buttons: [
			  {
				text: 'Annuler',
				handler: (data: any) => {
				  	// console.log('Canceled', data);
				}
			  },
			  {
				text: 'Confirmer',
				handler: (data: any) => {
					App.exitApp();
				}
			  }
			]
		  }).then(res => {
			  	// console.log(res);
				res.present();
		  });
		// console.log(action);
		

	}

	
}
