import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';


@Injectable({
  	providedIn: 'root'
})

export class UserService {

  	constructor(
		private http: HttpClient,
	) {}

	createAccount(form: FormGroup, locale: string) {

		var args = {
            "url": "sign_in",			
			"name": form.value.name,
			"email": form.value.email,
			"birthdate": form.value.birthday,
			"locale": locale,
			"password": form.value.password,
			
		};

		return this.http.post(
			'',  
			args 
		);

	}

	
	sendLogin(mail: string, password: string) {

		var args = {
            "url": "authenticate",			
			"email": mail,
			"password": password
			
		};
		
		return this.http.post(
			'',  
			args 
		);

	}

	sendLoginPro(code: string) {

		var args = {
            "url": "authenticate_pro",			
			"code": code
		};

		console.log(args);
		
		return this.http.post(
			'',  
			args 
		);

	}

	sendLogout() {
		localStorage.removeItem('tokenmht');
		return 1;

	}

	getToken(){
		return localStorage.getItem('tokenmht');
	}

	saveToken(token: string){

		localStorage.setItem('tokenmht', token);

	}
	  
	checkToken() {

		//return false;

		if (localStorage.length > 0) {
			
			let token = localStorage.getItem('tokenmht');
			if(token && token != 'undefined'){
				return true;	
			}

		} 

		return false;
		
	}

	sendGoogle(user: any, idToken: string) {

		var args = {
            "url": "google_oauth/callback",			
			"user": user,
			"idToken": idToken,			
		};
		
		return this.http.post(
			'',  
			args 
		);

	}

	sendFacebook(response: any, profil: any) {

		var args = {
            "url": "facebook_oauth/callback",			
			"user": response,
			"profil": profil			
		};
		
		return this.http.post(
			'',  
			args 
		);

	}	

	sendIOS(user: any, idToken: string) {

		var args = {
            "url": "apple_oauth/callback",			
			"user": user,
			"idToken": idToken,			
		};
		
		return this.http.post(
			'',  
			args 
		);

	}

	addSpotVisit(spot_id: number) {

		var args = {
            "url": "add_spot_visit",
			"spot_id": spot_id,				
		};
		
		return this.http.post(
			'',  
			args 
		);

	} 

	getVisits() {

		var args = {
            "url": "get_all_visits",				
		};
		
		return this.http.post(
			'',  
			args 
		);

	} 

	deleteVisits() {

		var args = {
            "url": "remove_all_visits",				
		};
		
		return this.http.post(
			'',  
			args 
		);

	} 

	getContenus(){

		var args = {
            "url": "get_purchases_history",				
		};
		
		return this.http.post(
			'',  
			args 
		);

	}

	get360(){

		var vr360Exist = JSON.parse( localStorage.getItem('vr360') );
		let  vr360= [];
		if ( vr360Exist ) {		 
		  for (var i = 0; i < vr360Exist.length; i++) {
			if (vr360Exist[i].title) {
				vr360.push(vr360Exist[i]);
			}
		  }
		}

		return vr360;


	}

	save360(vr360){
		localStorage.setItem( 'vr360', JSON.stringify(vr360) );
	}

	saveAudioGuide(audioguide){
		localStorage.setItem( 'audioguide', JSON.stringify(audioguide) );
	}
	
	getAudioGuide(){

		var AudioGuideExist = JSON.parse( localStorage.getItem('audioguide') );
		let  AudioGuide= [];
		if ( AudioGuideExist ) {		 
		  for (var i = 0; i < AudioGuideExist.length; i++) {
			if (AudioGuideExist[i]) {
				AudioGuide.push(AudioGuideExist[i]);
			}
		  }
		}

		return AudioGuide;

	}
	
	existAudioguide(audioguide){

		var audioguideExist = JSON.parse( localStorage.getItem('audioguide') );
		if ( audioguideExist ) {		 
		  for (var i = 0; i < audioguideExist.length; i++) {
			if (audioguideExist[i] == audioguide) {
				return 1;
			}
		  }
		}

		return 0;

	}

	saveVideo3D(audioguide){
		localStorage.setItem( 'video3D', JSON.stringify(audioguide) );
	}
	
	getVideo3D(){

		var AudioGuideExist = JSON.parse( localStorage.getItem('video3D') );
		let  AudioGuide= [];
		if ( AudioGuideExist ) {		 
		  for (var i = 0; i < AudioGuideExist.length; i++) {
			if (AudioGuideExist[i]) {
				AudioGuide.push(AudioGuideExist[i]);
			}
		  }
		}

		return AudioGuide;

	}
	
	existVideo3D(audioguide){

		var audioguideExist = JSON.parse( localStorage.getItem('video3D') );
		if ( audioguideExist ) {		 
		  for (var i = 0; i < audioguideExist.length; i++) {
			if (audioguideExist[i] == audioguide) {
				return 1;
			}
		  }
		}

		return 0;

	}

	setUserLang(lang: string) {

		var args = {
            "url": "set_user_lang",			
			"lang": lang			
		};
		
		return this.http.post(
			'',  
			args 
		);

	}
	
	saveLanguage(lang: string){

		localStorage.setItem('language', lang);

	}

	getLanguage(){
		if(localStorage.getItem('language')){
			return localStorage.getItem('language');
		}else{
			return 'fr';
		}
	}

	getQRCodeRoute(route: string) {

		var args = {
            "url": "get_route_from_qr_code",			
			"qrcode_hash": route			
		};
		
		return this.http.post(
			'',  
			args 
		);

	}
	
	getQRCodeRouteFromType(route: string) {

		var args = {
            "url": "get_route_from_qr_code",			
			"data": "type"+route			
		};
		
		return this.http.post(
			'',  
			args 
		);

	}

	saveQRCodeRoute(route: string){

		localStorage.setItem('qrcoderoute', route);

	}

	getQRCodeRouteLocal(){
		return localStorage.getItem('qrcoderoute');
	}

	saveMail(mail: string){

		localStorage.setItem('mail', mail);

	}

	getMail(){
		return localStorage.getItem('mail');
	}
	// getURLSecure(url) {

	// 	var args = {
    //         "url": url,				
	// 	};
		
	// 	return this.http.post(
	// 		'',  
	// 		args 
	// 	);

	// } 

	sendLoginFree(language) {

		var args = {
            "url": "authenticate_free",			
			"language": language			
		};
		
		return this.http.post(
			'',  
			args 
		);

	}

	getPremiumDay(email: string) {

		var args = {
            "url": "get_premium_day",			
			"email": email			
		};
		
		return this.http.post(
			'',  
			args 
		);

	}
	
}
