import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule, HttpClient} from '@angular/common/http';
// import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttppostInterceptor } from './mht/_services/httppost.interceptor';


// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
// import { Zip } from '@ionic-native/zip/ngx';
import { Media } from '@ionic-native/media/ngx';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Httpd, HttpdOptions } from '@ionic-native/httpd/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
// import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
// import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

// import * as Sentry from "@sentry/capacitor";
// import { init as sentryAngularInit, createErrorHandler }  from "@sentry/angular";
// const config = {
//     dsn: "https://02edf4a9f10b43a6801abfea155f3de3@sentry.maypic.fr/25",
//     debug: true,
// 	enableNative: false,
// 	release: '1.0'
//   }
// Sentry.init(config, sentryAngularInit);

import { IonicStorageModule } from '@ionic/storage-angular';
// import { LottieSplashScreen } from '@ionic-native/lottie-splash-screen/ngx';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

// import "cordova-plugin-purchase/www/store.d"

// import { CacheRouteReuseStrategy } from './mht/_directives/cache-route-reuse.strategy';

// import 'cordova-plugin-purchase';
// import CdvPurchase from 'cordova-plugin-purchase';


@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		// SocialLoginModule,
		
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
			  	provide: TranslateLoader,
			  	useFactory: createTranslateLoader,
			  	deps: [HttpClient]
			}
		}),
		IonicStorageModule.forRoot(),
		LottieModule.forRoot({ player: playerFactory }),
		// ServiceWorkerModule.register('ngsw-worker.js', {
		// 	enabled: true,
		// 	// Register the ServiceWorker as soon as the application is stable
		// 	// or after 30 seconds (whichever comes first).
		// 	registrationStrategy: 'registerWhenStable:30000'
		// })
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttppostInterceptor,
			multi: true
		},
		{
			provide: RouteReuseStrategy, 
			useClass: IonicRouteStrategy //CacheRouteReuseStrategy
		},
		// {
		// 	provide: ErrorHandler,
		// 	useValue: createErrorHandler(),
		// },
		// {
		// 	provide: 'SocialAuthServiceConfig',
		// 	useValue: {
		// 	  	autoLogin: false,
		// 	  	providers: [
		// 			{
		// 		  		id: GoogleLoginProvider.PROVIDER_ID,
		// 		  		provider: new GoogleLoginProvider('622151738978-f6i5v3d0ha0hv2kfgpe6mc1pn775aopv.apps.googleusercontent.com')
		// 			},
		// 			{
		// 				id: FacebookLoginProvider.PROVIDER_ID,
		// 				provider: new FacebookLoginProvider('822201821716244')
		// 			}
		// 		]
		// 	} as SocialAuthServiceConfig,
		// },
		// Zip,
		StatusBar,
		// SplashScreen,
		InAppPurchase2,
		Media,
		File,
		HTTP,
		InAppBrowser,
		GooglePlus,
		Httpd,
		Geolocation,
		// Facebook,
		// SignInWithApple
		// {
        //     provide: CdvPurchase.Store,
        //     useFactory: () => { return window.CdvPurchase.Store }
        // }
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [TranslateModule],

})
export class AppModule {}
